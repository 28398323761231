<template>
  <div class="statistics-sport-container">
    <div class="search-box">
      <Row>
        <Col span="24">
          <h2 class="search-title border-bottom">按条件搜索</h2>
        </Col>
      </Row>
      <Form class="search-form" :label-width="100" ref="formValidate" :model="searchForm" :rules="ruleValidate">
        <Row>
          <Col span="4">
            <Form-item label="会员姓名：">
              <Input v-model="searchForm.member_name" placeholder="请输入..."></Input>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="4">
            <Form-item label="性别：">
              <Select clearable v-model="searchForm.sex" placeholder="请选择">
                <Option :value="v.id" v-for="v in sexList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="上传时间：">
              <Date-picker @on-change="changeTime" type="daterange" placeholder="选择日期"></Date-picker>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
        </Row>
        <Row>
          <Col span="7">
            <Form-item label="总运动步数：">
              <Row>
                <Col span="10">
                  <Form-item prop="step_start">
                    <Input v-model="searchForm.step_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="step_end">
                    <Input v-model="searchForm.step_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">步</Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="总消耗：">
              <Row>
                <Col span="10">
                  <Form-item prop="total_start">
                    <Input v-model="searchForm.total_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="total_end">
                    <Input v-model="searchForm.total_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">kcal</Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="总有效消耗：">
              <Row>
                <Col span="10">
                  <Form-item prop="valid_start">
                    <Input v-model="searchForm.valid_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="valid_end">
                    <Input v-model="searchForm.valid_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">kcal</Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
        </Row>
        <Row>
          <Col span="7">
            <Form-item label="总运动时长：">
              <Row>
                <Col span="10">
                  <Form-item prop="time_start">
                    <Input v-model="searchForm.time_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="time_end">
                    <Input v-model="searchForm.time_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">min</Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="年龄：">
              <Row>
                <Col span="10">
                  <Form-item prop="age_start">
                    <Input v-model="searchForm.age_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="age_end">
                    <Input v-model="searchForm.age_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">岁</Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
        </Row>
        <Row>
          <Col span="7">
            <Form-item label="省份：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询所属店面的权限') > -1" v-model="pro_name"></Input>
              <Select clearable v-model="searchForm.pid" placeholder="请选择" @on-change="changePro" v-else>
                <Option :value="v.id" v-for="v in proList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="城市：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询所属店面的权限') > -1" v-model="city_name"></Input>
              <Select clearable v-model="searchForm.cid" placeholder="请选择" @on-change="changeCity" v-else>
                <Option :value="v.id" v-for="v in cityList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="门店：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询所属店面的权限') > -1" v-model="branch_name"></Input>
              <Select clearable v-model="searchForm.sid" placeholder="请选择" v-else>
                <Option :value="v.id" v-for="v in storeList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <div class="tac">
          <Button type="primary" class="search-btn" @click="handleSubmit('formValidate')">搜索</Button>
        </div>
      </Form>
    </div>
    <div class="export-div clearfix">
      <a href="javascript:;" class="export-btn" @click="exportData()" v-if="this.power.indexOf('数据统计--数据导出权限') > -1">导出数据</a>
    </div>
    <Table stripe :columns="columns" :data="sportData" @on-selection-change="selectOne" ref="table"></Table>
    <Row>
      <Col span="12">
        <div class="table-oprate">
          <!--<Button @click="">删除</Button>-->
        </div>
      </Col>
      <Col span="12">
        <span class="records">共{{pageTotal}}条记录</span>
      </Col>
    </Row>
    <div class="page-nations-wrapper">
      <div class="page-nations">
        <Page :total="pageTotal" :current="searchForm.page" :page-size="searchForm.size" @on-change="changePage"></Page>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import warningService from '@/services/warningService';
import statisticsService from '@/services/statisticsService';
export default {
	data() {
		const stepSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.step_end !== '') {
					this.$refs.formValidate.validateField('step_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.step_end !== '') {
						this.$refs.formValidate.validateField('step_end');
					}
					callback();
				}
			}
		};
		const stepLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.step_start !== '' &&
						Number(value) < Number(this.searchForm.step_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const totalSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.total_end !== '') {
					this.$refs.formValidate.validateField('total_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.total_end !== '') {
						this.$refs.formValidate.validateField('total_end');
					}
					callback();
				}
			}
		};
		const totalLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.total_start !== '' &&
						Number(value) < Number(this.searchForm.total_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const validSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.valid_end !== '') {
					this.$refs.formValidate.validateField('valid_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.valid_end !== '') {
						this.$refs.formValidate.validateField('valid_end');
					}
					callback();
				}
			}
		};
		const validLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.valid_start !== '' &&
						Number(value) < Number(this.searchForm.valid_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const timeSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.time_end !== '') {
					this.$refs.formValidate.validateField('time_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.time_end !== '') {
						this.$refs.formValidate.validateField('time_end');
					}
					callback();
				}
			}
		};
		const timeLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.time_start !== '' &&
						Number(value) < Number(this.searchForm.time_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const ageSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.age_end !== '') {
					this.$refs.formValidate.validateField('age_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.age_end !== '') {
						this.$refs.formValidate.validateField('age_end');
					}
					callback();
				}
			}
		};
		const ageLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.age_start !== '' &&
						Number(value) < Number(this.searchForm.age_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		return {
			searchForm: {
				page: 1,
				size: 10,
				member_name: '',
				sex: '',
				pid: '',
				cid: '',
				sid: '',
				start: '',
				end: '',
				step_start: '',
				step_end: '',
				total_start: '',
				total_end: '',
				valid_start: '',
				valid_end: '',
				time_start: '',
				time_end: '',
				age_start: '',
				age_end: '',
			},
			exportForm: {
				member_name: '',
				sex: '',
				pid: '',
				cid: '',
				sid: '',
				start: '',
				end: '',
				step_start: '',
				step_end: '',
				total_start: '',
				total_end: '',
				valid_start: '',
				valid_end: '',
				time_start: '',
				time_end: '',
				age_start: '',
				age_end: '',
			},
			sexList: [
				{
					id: 0,
					name: '男',
				},
				{
					id: 1,
					name: '女',
				},
			],
			proList: [],
			cityList: [],
			city: {},
			storeList: [],
			store: {},
			columns: [
				// { type: 'selection', width: 60, align: 'center' },
				{ title: '所属分院', key: 'branch_name', align: 'center' },
				{
					title: '会员姓名',
					key: 'member_name',
					align: 'center',
					render: (h, params) => {
						return h('div', [
							h(
								'a',
								{
									style: {
										color: '#ff8400',
									},
									attrs: {
										target: '_blank',
										href:
											'#/base/member/detail/' +
											this.sportData[params.index].member_id +
											'/' +
											2 +
											'/' +
											1,
									},
								},
								params.row.member_name,
							),
						]);
					},
				},
				{
					title: '性别',
					key: 'sex',
					align: 'center',
					render: (h, params) => {
						if (params.row.sex === 0) {
							return h('div', '男');
						} else if (params.row.sex === 1) {
							return h('div', '女');
						} else {
							return h('div', params.row.sex);
						}
					},
				},
				{
					title: '年龄',
					key: 'age',
					align: 'center',
					render: (h, params) => {
						if (params.row.age === null) {
							return h('div', params.row.age);
						} else {
							return h('div', params.row.age + '岁');
						}
					},
				},
				{
					title: '总消耗',
					key: 'total',
					align: 'center',
					render: (h, params) => {
						if (params.row.total === null) {
							return h('div', params.row.total);
						} else {
							return h('div', params.row.total + 'kcal');
						}
					},
				},
				{
					title: '总有效消耗量',
					key: 'total_effective',
					align: 'center',
					render: (h, params) => {
						if (params.row.total_effective === null) {
							return h('div', params.row.total_effective);
						} else {
							return h('div', params.row.total_effective + 'kcal');
						}
					},
				},
				{
					title: '总运动步数',
					key: 'total_step',
					align: 'center',
					render: (h, params) => {
						if (params.row.total_step === null) {
							return h('div', params.row.total_step);
						} else {
							return h('div', params.row.total_step + '步');
						}
					},
				},
				{
					title: '总运动时长',
					key: 'total_time',
					align: 'center',
					render: (h, params) => {
						if (params.row.total_time === null) {
							return h('div', params.row.total_time);
						} else {
							return h('div', params.row.total_time + 'min');
						}
					},
				},
				{ title: '上传时间', key: 'create_time', align: 'center' },
			],
			sportData: [],
			pageTotal: 0,
			ruleValidate: {
				step_start: [{ validator: stepSmall, trigger: 'blur' }],
				step_end: [{ validator: stepLarge, trigger: 'blur' }],
				total_start: [{ validator: totalSmall, trigger: 'blur' }],
				total_end: [{ validator: totalLarge, trigger: 'blur' }],
				valid_start: [{ validator: validSmall, trigger: 'blur' }],
				valid_end: [{ validator: validLarge, trigger: 'blur' }],
				time_start: [{ validator: timeSmall, trigger: 'blur' }],
				time_end: [{ validator: timeLarge, trigger: 'blur' }],
				age_start: [{ validator: ageSmall, trigger: 'blur' }],
				age_end: [{ validator: ageLarge, trigger: 'blur' }],
			},
			power: '',
			pro_name: '',
			city_name: '',
			branch_name: '',
		};
	},
	created() {
		this.power = localStorage.getItem('userPower');
		this.pro_name = JSON.parse(localStorage.getItem('userInfo')).user_info.pro_name;
		this.city_name = JSON.parse(localStorage.getItem('userInfo')).user_info.city_name;
		this.branch_name = JSON.parse(localStorage.getItem('userInfo')).user_info.branch_name;
		if (
			this.power.indexOf('数据统计--查询全国数据的权限') === -1 &&
			this.power.indexOf('数据统计--查询区域数据的权限') === -1
		) {
			this.searchForm.pid = JSON.parse(localStorage.getItem('userInfo')).user_info.pid;
			this.searchForm.cid = JSON.parse(localStorage.getItem('userInfo')).user_info.cid;
			this.searchForm.sid = JSON.parse(localStorage.getItem('userInfo')).user_info.sid;
		}
		this.getSportData();
		warningService.getWarningSelect().then((data) => {
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
	},
	methods: {
		changePro() {
			this.cityList = this.city[this.searchForm.pid];
			this.searchForm.cid = '';
		},
		changeCity() {
			this.storeList = this.store[this.searchForm.cid];
			this.searchForm.sid = '';
		},
		getSportData() {
			statisticsService.getSportData(this.searchForm).then((data) => {
				this.sportData = data.list;
				this.pageTotal = data.row_size;
			});
		},
		handleSubmit(name) {
			this.$refs[name].validate((valid) => {
				if (valid) {
					this.searchForm.page = 1;
					this.getSportData();
					this.exportForm.member_name = this.searchForm.member_name;
					this.exportForm.sex = this.searchForm.sex;
					this.exportForm.pid = this.searchForm.pid;
					this.exportForm.cid = this.searchForm.cid;
					this.exportForm.sid = this.searchForm.sid;
					this.exportForm.start = this.searchForm.start;
					this.exportForm.end = this.searchForm.end || '';
					this.exportForm.step_start = this.searchForm.step_start;
					this.exportForm.step_end = this.searchForm.step_end;
					this.exportForm.total_start = this.searchForm.total_start;
					this.exportForm.total_end = this.searchForm.total_end;
					this.exportForm.valid_start = this.searchForm.valid_start;
					this.exportForm.valid_end = this.searchForm.valid_end;
					this.exportForm.time_start = this.searchForm.time_start;
					this.exportForm.time_end = this.searchForm.time_end;
					this.exportForm.age_start = this.searchForm.age_start;
					this.exportForm.age_end = this.searchForm.age_end;
				}
			});
		},
		changeTime(date) {
			this.searchForm.start = date[0];
			this.searchForm.end = date[1];
		},
		changePage(page) {
			this.searchForm.page = page;
			this.getSportData();
		},
		selectOne() {},
		exportData() {
			location.href =
				'http://conlife.sskh.net:9928/api/sport/export?member_name=' +
				this.exportForm.member_name +
				'&sex=' +
				this.exportForm.sex +
				'&pid=' +
				this.exportForm.pid +
				'&cid=' +
				this.exportForm.cid +
				'&sid=' +
				this.exportForm.sid +
				'&start=' +
				this.exportForm.start +
				'&end=' +
				this.exportForm.end +
				'&step_start=' +
				this.exportForm.step_start +
				'&step_end=' +
				this.exportForm.step_end +
				'&total_start=' +
				this.exportForm.total_start +
				'&total_end=' +
				this.exportForm.total_end +
				'&valid_start=' +
				this.exportForm.valid_start +
				'&valid_end=' +
				this.exportForm.valid_end +
				'&time_start=' +
				this.exportForm.time_start +
				'&time_end=' +
				this.exportForm.time_end +
				'&age_start=' +
				this.exportForm.age_start +
				'&age_end=' +
				this.exportForm.age_end;
		},
	},
};
</script>

<style lang="css" scoped>
.statistics-sport-container .search-box {
  border: 1px solid #d7dde4;
}
</style>
